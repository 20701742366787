<template>
  <div>
    <div class="text-right pb-2">
      <b-button
        type="submit"
        variant="colorYellow"
        class="mr-1"
        rounded
        @click="
          () => {
            this.$router.push({ name: 'InstructorAssignmentCreate' });
          }
        "
      >
        <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
        <span class="text-colorWhite">New Assignment</span>
      </b-button>
      <b-button
        type="submit"
        variant="colorBlue"
        rounded
        class="align-items-center"
        @click="
          () => {
            this.$router.push({ name: 'InstructorLectureCreate' });
          }
        "
      >
        <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
        <span class="text-colorWhite">New Lecture</span>
      </b-button>
    </div>
    <b-row class="d-flex justify-content-between">
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorBlue font-weight-bolder">
            {{ stats.class_count }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              My Classes
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/classes.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorOrange font-weight-bolder"
          >
            {{ stats.subject_count }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              My Subjects
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/subjects.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorYellow font-weight-bolder"
          >
            {{ stats.total_lectures_count }}
          </h1>

          <div>
            <h5
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Lectures Created
            </h5>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/lectures.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorRed font-weight-bolder">
            {{ stats.total_students_count }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              My Students
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/students.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between mt-2 h-50">
      <b-col
        md=""
        class="p-0 m-0 bg-colorWhite w-100"
        style="border-radius: 20px"
      >
        <b-row>
          <b-col md="">
            <div class="pt-2 pl-2 d-flex w-100">
              <div class="w-50">
                <h3 class="font-weight-bolder text-colorBlack">Classes</h3>
                <p class="font-weight-bold text-colorGray mb-50">
                  Your subjects are display here
                </p>
              </div>
              <div class="w-50 text-right pr-2">
                <p class="font-weight-bolder text-colorBlue">
                  <b-link :to="{ name: 'Lecture' }"> View All</b-link>
                </p>
              </div>
            </div>
            <div class="d-flex w-100" v-for="classes in stats.assigned_classes" :key="classes.id">
              <b-card border-variant="light" class="mx-2 mb-50 w-100">
                <div class="w-100 d-flex align-items-end">
                  <div class="w-50">
                    <b-card-title class="text-colorBlack font-weight-bolder">
                      {{ classes.name }}
                    </b-card-title>
                    <b-card-text class="font-weight-bold text-colorGray">
                      Subjects: <span v-for="(subject, index) in classes.subjects" :key="subject.id">
                                  {{ subject.name }}<span v-if="index < classes.subjects.length - 1">, </span>
                                </span> <br />
                      Lecture: {{ classes.lectures }}
                    </b-card-text>
                  </div>
                  <div class="w-50 text-right">
                    <b-link>
                      <img
                        src="@/assets/images/icons/dashboard/greater.svg"
                        img-fluid
                    /></b-link>
                  </div>
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md=""
        class="p-0 m-0 bg-colorWhite w-100"
        style="border-radius: 20px"
      >
        <b-row>
          <b-col md="">
            <div class="pt-2 pl-2 d-flex w-100">
              <div class="w-50">
                <h3 class="font-weight-bolder text-colorBlack">Assignments</h3>
                <p class="font-weight-bold text-colorGray mb-50">
                  Check your students assignments
                </p>
              </div>
              <div class="w-50 text-right pr-2">
                <p class="font-weight-bolder text-colorBlue">
                  <b-link :to="{ name: 'InstructorAssignment' }"> View All</b-link>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col md="">
            <div class="d-flex w-100" v-for="assignment in stats.assignments" :key="assignment.id">
              <b-card border-variant="light" class="mx-2 mb-50 w-100">
                <b-row class="d-flex align-items-start">
                  <b-col md="6" align-self="stretch">
                    <h5
                      class="text-colorBlack font-weight-bolder"
                      style="font-size: 1.2rem"
                    >
                      {{ assignment.name }}
                    </h5>
                  </b-col>
                  <b-col
                    md="3"
                    class="text-left text-md-center mt-50 mt-md-0"
                    align-self="stretch"
                  >
                    <h5
                      class="text-colorBlack font-weight-bolder m-0 p-0"
                      style="font-size: 1.2rem"
                    >
                      {{ assignment.subject_data.name }}
                    </h5>
                    <p class="font-weight-normal text-colorGray p-0 m-0">
                      Subject
                    </p>
                  </b-col>
                  <b-col
                    md="3"
                    class="text-left text-md-center mt-50 mt-md-0"
                    align-self="stretch"
                  >
                    <h5
                      class="text-colorBlack font-weight-bolder m-0 p-0"
                      style="font-size: 1.2rem"
                    >
                      {{ assignment.classes_data.name }}
                    </h5>
                    <p class="font-weight-normal text-colorGray p-0 m-0">
                      Class
                    </p>
                  </b-col>
                </b-row>

                <div class="py-50">
                  <p class="font-weight-normal text-danger">
                    Deadline: {{ assignment.deadline }}
                  </p>
                </div>
                <!-- <b-row class="d-flex align-items-center">
                  <b-col md="3" align-self="stretch">
                    <p class="font-weight-normal text-colorBlack m-0 p-0">
                      Grading 12/20
                    </p>
                    <b-progress
                      class="mt-50"
                      :value="submitted"
                      :max="max"
                      show-progress
                      animated
                    ></b-progress>
                  </b-col>
                  <b-col md="3" class="text-center" align-self="stretch">
                    <p class="font-weight-normal text-colorBlack m-0 p-0">
                      Grading 12/20
                    </p>
                    <b-progress
                      class="mt-50"
                      :value="grade"
                      :max="max"
                      show-progress
                      animated
                    ></b-progress>
                  </b-col>
                  <b-col
                    md="6"
                    class="d-flex align-items-center justify-content-end"
                  >
                    <h6 class="text-colorBlue font-weight-bolder m-0 p-0">
                      View Assignment
                    </h6>
                    <b-link>
                      <img
                        src="@/assets/images/icons/dashboard/greater.svg"
                        img-fluid
                        class="ml-1"
                    /></b-link>
                  </b-col>
                </b-row> -->
              </b-card></div
          ></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      subjectCode: "",
      grade: 70,
      submitted: 47,
      max: 100,
      modalVisible: false,
      modalVisibleFeedback: false,
      modalVisibleUpload: false,
      stats: {}
    };
  },
  methods: {
    ...mapActions({
      getTeacherStats: "appData/getTeacherStats",
    }),
    async getDashboardData(){
      try {
        const res = await this.getTeacherStats();
        this.stats = res.data;
      } catch (error) {
        this.displayError(error);
      }
    }
  },
  async mounted(){
    await this.getDashboardData();
  },
};
</script>

<style></style>
